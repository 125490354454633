.sample-details-tooltip {
    padding: 0px !important;
    min-width: 500px;
    background: rgba(97, 97, 97, 1) !important;

}

.sample-details-container {
    padding: 18px;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: auto;
}

.sample-details-container > :not(:first-child) {
    margin-top: 14px;
}

.sample-details-section-header {
    padding: 12px 0px;
    font-weight: bold !important;
}

.sample-details-content {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px 8px;
}

.sample-details-content > :nth-child(2n) {
    display: block;
    max-width: 300px;
    white-space: normal;
    word-wrap: break-word;
}