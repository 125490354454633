.navbar {
  height: 75px;
}
nav.navbar,
.navbar-default {
  background-color: transparent !important;
  padding: 0px;
}

.navbar > .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: unset;
}

/* Navigation bar border */
.sticky-nav {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  height: 75px;

  padding: 8px 20px;
  padding-left: 45px;
  /* #7d90b8 */
}

.nav-items {
  display: flex;
  /* align-items: inherit; */
  gap: 14px;
}

.sticky-nav-v2 {
  border-bottom: 1px solid #ced4da;
}
.navbar-default {
  background-image: none;
  border-radius: 0;
}

.navbar-default .navbar-nav > li {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-bar-item > * {
  color: white !important;
  font-weight: 700;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.nav-bar-item-v2 > * {
  color: black !important;
}

.nav-bar-item > *:hover {
  color: rgba(156, 194, 230, 0.67);
  text-decoration: underline;
  text-decoration-color: #9abee3;
}

.navbar-default .navbar-brand {
  color: white;
}

.navbar-default .navbar-toggle {
  border-color: white;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: white;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: #9abee3;
}

.navbar-default .navbar-brand:hover {
  text-decoration: underline;
  text-decoration-color: #9abee3;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:active {
  background-color: #9abee3;
}

.navbar-default .navbar-brand:active {
  text-decoration: underline;
  text-decoration-color: #9abee3;
}

.navbar-default .navbar-brand {
  font-family: sans-serif;
}

.navbar-nav {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-icon {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.nav-bar-static {
  background-color: #7d90b8 !important;
}