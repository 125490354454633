/* The sidebar menu */
.sidenav {
  width: 45px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #7d90b8; /* Pangea Color */
  overflow-x: hidden; /* Disable horizontal scroll */
  z-index: 1; /* Stay on top */
  height: 100%;
  padding: 75px 0px;

  display: grid;
  grid-template-rows: auto 0.5fr auto 1fr;
  /* align-items: center; */
}

.sidenav-actions {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  grid-row: 3;
}

/* The navigation menu links */
.sidenav a {
  padding: 4px 5px 4px 8px;
  text-decoration: none;
  font-size: 25px;
  color: white;
}

.export-btn-closed {
  margin-top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  background-color: #9cc2e6;
}

/* Style page content */
.main {
  margin-left: 160px; /* Same as the width of the sidebar */
  padding: 0px 10px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}