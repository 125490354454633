.jumbotron {
  min-height: 450px;
  width: 100%;
  background-color: transparent;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 96%,
    15% 96%,
    17% 100%,
    19% 96%,
    48% 96%,
    50% 100%,
    52% 96%,
    81% 96%,
    83% 100%,
    85% 96%,
    0 96%
  );
  -webkit-clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 96%,
    15% 96%,
    17% 100%,
    19% 96%,
    48% 96%,
    50% 100%,
    52% 96%,
    81% 96%,
    83% 100%,
    85% 96%,
    0 96%
  );
  -moz-clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 96%,
    15% 96%,
    17% 100%,
    19% 96%,
    48% 96%,
    50% 100%,
    52% 96%,
    81% 96%,
    83% 100%,
    85% 96%,
    0 96%
  );
  padding: 0px;
  margin-bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.jumbotron-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  padding: 60px 24px;
}

.video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  z-index: -1;
  transform: translate(-50%, -30%);
}

/* remove */
.mission {
  text-align: center;
  color: #808080;
}

.mission-content {
  max-width:1000px;
  margin: auto;
}

.mission-title {
  color: #9cc2e6;
}
.text {
  color: white;
  text-align: center;
  padding: 16px;
}

.header {
  padding-top: 135px;
  font-size: 3em;
}

.section-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  padding: 20px 4px 14px 4px;

  float: left;
  color: white;
  flex: 1;
  border: rgba(156, 194, 230, 0.95) 0.5px solid;
  border-top: none;
  background-color: rgba(156, 194, 230, 0.67);
  text-align: center;
}

/* the sgp info overlayer (i.e results,samples,countries) */

#wrapper {
  width: 100%;
  margin-top: -18px;

  display: flex;
}

#wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.login-btn {
  background: linear-gradient(to right, #9cc2e6 2%, #8a9bbf 100%);
  border: none;
  padding: 10px 20px 10px 20px;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
}

.login-btn:hover {
  color: rgba(156, 194, 230, 0.67);
}

.typing {
  color: white;
  font-size: 3em !important;
  pointer-events: none;
  text-align: center;
}

.search-button {
  background: linear-gradient(to right, #9cc2e6 2%, #8a9bbf 100%);
}