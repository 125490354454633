/* .section3 {
  display: inline-flex;
  height: 200px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.section3-right {
  box-sizing: border-box;
  width: 20%;
  background-color: #fe3562;
  color: white;
}

.section3-left {
  background-image: url("https://static1.squarespace.com/static/589e0b7346c3c44d745653e1/t/58b886e703596ed6a2c60679/1488488176463/");
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  width: 80%;
  background-color: black;
}

.section3-txt {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 55px;
  text-align: center;
  margin: 5%;
} */

.outer-doc {
  padding-top: 85px;
  margin: 0px 50px;

  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: minmax(1fr, auto);
  flex: 1;
  gap: 36px;
  padding-bottom: 20px;
}

.main-title-doc {
  text-align: center;
  color: #9cc2e6;
  border: 3px solid #9cc2e6;
}

.title-doc {
  color: #9cc2e6;
  text-decoration: underline;
}

.documentation-nav-bar {
  display: flex;
  flex-direction: column;
}

.documentation-nav-bar > .documentation-nav-bar-item:not(:first-child) {
  margin-top: 8px;
}

.documentation-nav-bar-item {
  color: black !important;
  padding: 14px !important;
  font-weight: bold !important;
}

.documentation-nav-bar-item:active {
  color: black !important
}

.documentation-nav-bar-item.active {
  background-color: #9cc2ff3a !important
}

.documentation-nav-bar-item:hover {
  background-color: #9cc2ff22 !important;
  color: rgba(156, 194, 230, 0.67) !important;
  text-decoration: none !important;
}

.documentation-nav-bar-header {
  margin: 14px 0px;
  padding-left: 8px;
  text-transform: uppercase;
}

.documentation-contents-header {
  font-weight: bold !important;
  padding: 0.67rem 0px;
}

.documentation-content-enter {
  opacity: 0.1;
}

.documentation-content-enter.documentation-content-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.documentation-content-exit {
  display: none;
}

.documentation-content-exit.documentation-content-exit-active {
  opacity: 0.1;
  transition: opacity 300ms ease-in;
}

.swagger-frame {
  min-height: 100%;
  width: 100%;
}

.videos-container {
  display: flex;
  flex-direction: column;
}

.video-title {
  color: black;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: large;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.videos-container > :not(:first-child) {
  margin-top: 24px;
}
.videos-container-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
.videos-container-wrapper > iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.documentation-attributes-table {
  width: 850px;
  border-collapse: collapse;
}

.documentation-attributes-table, .documentation-attributes-table th, .documentation-attributes-table td {
  border: 1px solid black;
}

.documentation-attributes-table td {
  padding: 4px;
}

.documentation-page {
  max-width: 1024px;
  width: 100%;
}