.about {
  padding: 25px;
  display: flex;
  flex-direction: column;
  max-width: 1025px;
  margin: auto;
}

.thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.thumbnail-image-wrapper {
  height: 180px;
  width: 180px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail img {
  height: 100%;
  width: auto;
}

.thumbnail-image-wrapper {
  position: relative;
  overflow: hidden;

  width: 180px;
  height: 180px;
}

.thumbnail-text {
  text-align: center;
}

.mission-title {
  color: #9cc2e6;
  padding-bottom: 10px;
  text-decoration: underline #9cc2e6;
}

.title-doc {
  color: #9cc2e6;
  text-decoration: underline;
}

.photos-grid {
  display: grid;
  gap: 14px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}