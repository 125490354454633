.navbar-default .navbar-nav > li > a {
  font-family: sans-serif;
}

.navbar-nav > li {
  padding: 10px;
}

.navbar-nav > li {
  float: left;
  position: relative;
}

/* .navbar-default .navbar-nav .active {
  background-color: aquamarine;
} */

.nav-bar-item > .active::after {
  border-bottom: 2px solid #8a9bbf;
  bottom: -18px;
  content: " ";
  left: 0;
  position: absolute;
  right: 0;
}

.hide-active > .active {
  visibility: hidden;
}